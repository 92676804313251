(function(){
    var load_library_on_load = function(script_src, onload_callback_func){
        var head = document.getElementsByTagName('head').item(0);
        var scr = document.createElement('script');
        scr.type = 'text/javascript';
        scr.onreadystatechange = function(){
            if (window.opera || this.readyState != 'loaded' || this.className == 'processed') 
                return;
            this.className = 'processed';
            onload_callback_func();
        };
        scr.onload = function(){
            if (this.className == 'processed') 
                return;
            this.className = 'processed';
            onload_callback_func();
        };
        if (scr.className == 'processed') {
            onload_callback_func();
        }
        scr.src = script_src;
        head.appendChild(scr);
        window.setTimeout(function(){
            if (scr.className != 'processed') 
                onload_callback_func();
        }, 300);
    };
    
    load_library_on_load(location.host == 'www.bodyjet.jp' ? 'http://' + location.host + '/js/research_setup.js' : 'http://4a-c08-c2.data-hotel.net/pc/bodyjet/js/research_setup.js', function(){
        var ldReSearch = new LDReSearch({
            client_id: 'ld_blogjs_sl'
        });
        
        var ldReSearchFormatter = function(p){
            var host = 'http://4a-c08-c2.data-hotel.net/pc/bodyjet/';
            if (location.host == 'www.bodyjet.jp') {
                host = 'http://' + location.host + '/';
            }
            
            var str = ['<h2><img src="' + host + 'img/header_search_title.gif" alt="さらに検索する" /></h2>', '<form action="' + host + 'qa/keyword/" method="post">', '<dl>', '<dt><input type="text" name= "keyword" value="' + p.escaped_query + '" readonly="readonly" /></dt>', '<dd><input type="image" src="' + host + 'img/header_search_button.gif" value="" /></dd>', '</dl>', '</form>', '<p class="close">', '<a href="#" onclick="document.getElementById(\'popup_keyword\').style.visibility = \'hidden\'" >', '<img src="' + host + 'img/header_search_close_button.gif" alt="閉じる" />', '</a>', '</p>', ].join('');
            var hd = document.createElement('div');
            hd.id = 'popup_keyword';
            hd.className = "search";
            hd.innerHTML = str;
			if (p.escaped_query != '') {
				document.getElementById('header').appendChild(hd);
			}
        };
        ldReSearch.writeWithFormatter(ldReSearchFormatter);
    });
})();
